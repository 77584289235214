import config from "config"

const BASE_URL = config.API_URL ? config.API_URL : ""

export const loginUrls = {
    SIGNIN: `${BASE_URL}/auth/signin`
}

export const clientsUrls = {
    GET_CLIENTS: `${BASE_URL}/clients`,
    GET_CLIENTS_MINIFIED: `${BASE_URL}/clients/reduced`
}

export const monitoringUrls = {
    GET_TRACKINGS: `${BASE_URL}/trackings`,
    ADD_OR_UPDATE: `${BASE_URL}/trackings/project`
}

export const centersUrls = {
    GET_CENTERS: `${BASE_URL}/centers`
}

export const loggerUrls = {
    CREATE: `${BASE_URL}/error_log`
}

export const companiesUrls = {
    GET_COMPANIES: `${BASE_URL}/companies`
}

export const employeesUrls = {
    GET_EMPLOYEES: `${BASE_URL}/users`,
    SIGNUP_EMPLOYEES: `${BASE_URL}/auth/signup`,
    UPDATE_EMPLOYEES: `${BASE_URL}/users`
}

export const businessRulesUrls = {
    GET_BUSINESS_RULES: `${BASE_URL}/business_rules`,
    GENDER: `${BASE_URL}/business_rules?name=gender`,
    APP: `${BASE_URL}/business_rules?name=aplicaciones`,
    COMMENTS: `${BASE_URL}/business_rules?name=comentarios`,
    REASON: `${BASE_URL}/business_rules?name=motivosPausaCancelar`,
    CALCULATE: `${BASE_URL}/business_rules/calculate`
}

export const projectsUrls = {
    PROJECT_BASE: `${BASE_URL}/projects`,
    PROJECT_BY_CLIENT: `${BASE_URL}/projects/client`,
    GET_MONITORING: `${BASE_URL}/projects/monitoring`,
}

export const filesUrls = {
    UPLOAD: `${BASE_URL}/files/upload`,
    GET_FILES: `${BASE_URL}/files/getFiles`,
    DOWNLOAD: `${BASE_URL}/files/getFile`
}

export const emailUrls = {
    SEND: `${BASE_URL}/email/send`,
}