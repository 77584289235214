const source = window._env_? window._env_ : process.env
const config = {
    API_URL: source.REACT_APP_API_URL || "/api",
    DISTANCE_SESSION_ACTIVE: source.REACT_APP_DISTANCE_SESSION_ACTIVE || "true",
    DISTANCE_PERCENT: source.REACT_APP_DISTANCE_PERCENT || "70",
    FACIAL_KEYS: source.REACT_APP_FACIAL_KEYS?.split(",") || [],
    BODY_FEM_KEYS: source.REACT_APP_BODY_FEM_KEYS?.split(",") || [],
    BODY_MASC_KEYS: source.REACT_APP_BODY_MASC_KEYS?.split(",") || [],
    ZONES_ORDERER_KEYS: source.REACT_APP_ZONES_ORDERER_KEYS?.split(",") || []
};

export default config;
